import React from 'react';
import {Totals, TotalsDataHook} from '../../../../common/components/Totals/Totals';
import {Text, TextPriority} from 'wix-ui-tpa';
import {useLocaleKeys} from '../../../../locale-keys/LocaleKeys';
import {classes} from './PaymentRequestTotals.st.css';

export enum PaymentRequestTotalsDataHooks {
  Section = 'PaymentRequestTotalsDataHooks.section',
  TotalsSectionWrapper = 'PaymentRequestTotalsDataHooks.totalsSectionWrapper',
  TotalSection = 'PaymentRequestTotalsDataHooks.totalSection',
  TotalRow = 'PaymentRequestTotalsDataHooks.totalRow',
  Price = 'PaymentRequestTotalsDataHooks.price',
  ExpirationTextRow = 'PaymentRequestTotalsDataHooks.expirationText',
}

export interface PaymentRequestTotalsProps {
  total: string;
}

export const PaymentRequestTotals = ({total}: PaymentRequestTotalsProps) => {
  const localeKeys = useLocaleKeys();

  return (
    <section
      className={classes.totalsContainer}
      data-hook={PaymentRequestTotalsDataHooks.Section}
      aria-label={localeKeys.checkout.orderTotal.fullSection.ariaLabel()}>
      <Totals dataHook={PaymentRequestTotalsDataHooks.TotalsSectionWrapper}>
        <Totals.TotalsSection dataHook={PaymentRequestTotalsDataHooks.TotalSection}>
          <Totals.TotalsRow
            dataHook={PaymentRequestTotalsDataHooks.TotalRow}
            title={() => (
              <Text className={classes.totalRowText} data-hook={TotalsDataHook.TotalsRowTitle}>
                {localeKeys.checkout.order_summary.totals.total()}
              </Text>
            )}
            value={() => (
              <Text data-hook={PaymentRequestTotalsDataHooks.Price} className={classes.totalRowText}>
                {total}
              </Text>
            )}
          />
          <Totals.TotalsRow
            dataHook={PaymentRequestTotalsDataHooks.ExpirationTextRow}
            title={() => (
              <Text
                data-hook={TotalsDataHook.TotalsRowTitle}
                className={classes.totalRowExpirationText}
                priority={TextPriority.secondary}>
                {'The paylink is valid until 11 Jan, 23'}
              </Text>
            )}
          />
        </Totals.TotalsSection>
      </Totals>
    </section>
  );
};
