import React, {useState} from 'react';
import {StatesButton, StatesButtonStates, ThreeDotsLoader, ButtonSize} from 'wix-ui-tpa/cssVars';
import {classes} from './PayNowButton.st.css';
import {useErrorMonitor} from '@wix/yoshi-flow-editor';
import {usePaymentsApi} from '../../../../common/components/WithPaymentsApi/WithPaymentsApi';
import {useControllerProps} from '../../Widget/ControllerContext';
import {toMonitorError} from '../../../../domain/utils/errorMonitor.utils';
import {useLocaleKeys} from '../../../../locale-keys/LocaleKeys';

export const PayNowButtonDataHook = 'pay-now-button';

export const PayNowButton = () => {
  const errorMonitor = useErrorMonitor();
  const localeKeys = useLocaleKeys();
  const [payNowButtonState, setPayNowButtonState] = useState(StatesButtonStates.IDLE);
  const [payNowButtonDisabled, setPayNowButtonDisabled] = useState(false);
  const {
    paymentRequestStore: {paymentRequest},
  } = useControllerProps();

  const {paymentsApi} = usePaymentsApi();

  const validateAndSubmit = async () => {
    try {
      setPayNowButtonState(StatesButtonStates.IN_PROGRESS);
      setPayNowButtonDisabled(true);
      const validation = await paymentsApi?.validate();
      if (!validation?.isValid) {
        return;
      }

      errorMonitor.addBreadcrumb({
        message: 'cashier billing form is valid',
      });

      await paymentsApi?.prepareToPay?.();
      const paymentResult = await paymentsApi?.pay?.({orderId: paymentRequest.paymentGatewayOrderId});
      errorMonitor.addBreadcrumb({message: 'Link paid'});
      if (paymentResult?.clientStatus === 'Approved' || paymentResult?.clientStatus === 'Pending') {
        //handle navigation
      }
    } catch (e) {
      errorMonitor.captureException(...toMonitorError(e, 'PayNowButton payment'));
      await paymentsApi?.cancelPayment?.();
    } finally {
      setPayNowButtonState(StatesButtonStates.IDLE);
      setPayNowButtonDisabled(false);
    }
  };

  return (
    <StatesButton
      className={classes.payNowButton}
      data-hook={PayNowButtonDataHook}
      disabled={payNowButtonDisabled}
      upgrade={true}
      size={ButtonSize.medium}
      onClick={() => {
        void validateAndSubmit();
      }}
      idleContent={localeKeys.checkout.payLinks.mainCta()}
      state={payNowButtonState}
      inProgressContent={<ThreeDotsLoader className={classes.threeDotButton} />}
      wrapContent
    />
  );
};
